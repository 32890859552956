import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FilledInfoBar } from '../../../../components/_DesignSystemWrappers/InfoBar/FilledInfoBar';
import messages from './messages';

export interface IMFARecoveryProps {
  onBack: () => void;
}

export function MFARecoveryForm(props: IMFARecoveryProps) {
  return (
    <Box sx={{ mt: -2 }}>
      <FilledInfoBar>
        <Typography variant="body2">
          <FormattedMessage
            {...messages.ImsTwoFactorAuthenticationCantAccessAlertText}
            values={{
              b: (chunks) => <b>{chunks}</b>,
              link: (chunks) => (
                <Link href="https://www.2n.com/en-GB/support/#verticaltabscontainer-do-you-need-support-or-help">
                  {chunks}
                </Link>
              ),
            }}
          />
        </Typography>
      </FilledInfoBar>
      <Link component="button" onClick={() => props.onBack()}>
        <FormattedMessage {...messages.ImsTwoFactorAuthenticationCantAccessBack} />
      </Link>
    </Box>
  );
}
