import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import { ICompanyRoles } from '../../../hooks/rolesHooks';
import { isLoggedInSelector } from '../../../modules/auth/selectors';
import { TId } from '../../../modules/commonTypes';
import {
  isMarketer as isMarketerSelector,
  isSuperadmin as isSuperadminSelector,
} from '../../../modules/user/selectors';
import messages from '../messages';
import { getRedirectPathForCurrentUser } from '../redirectPath';

export enum LoginPageStep {
  Login = 'login',
  MFA = 'mfa',
  MFARecovery = 'MFARecovery',
}

export interface ICurrentUserLogin {
  id: TId;
  lastVisitedSiteId?: number | null;
  lastVisitedCompanyId?: number | null;
  companies: ICompanyRoles[];
}

export interface IUseLoginPage {
  redirectToAppWhenUserIsLoggedIn: () => void;
  getTitle: (LoginPageStep: LoginPageStep) => string;
}

export interface IUseLoginPageProps {
  currentUser: ICurrentUserLogin;
  isOryUserLoggedIn: boolean;
}

export function useLoginPage({ currentUser, isOryUserLoggedIn }: IUseLoginPageProps): IUseLoginPage {
  const loggedIn = useSelector(isLoggedInSelector);
  const navigate = useNavigate();
  const isSuperadmin = useSelector(isSuperadminSelector);
  const isMarketer = useSelector(isMarketerSelector);
  const { formatMessage } = useIntl();

  const getTitle = (loginPageStep: LoginPageStep) => {
    if (loginPageStep === LoginPageStep.MFA) {
      return formatMessage(messages.ImsTwoFactorAuthenticationTitle);
    }
    if (loginPageStep === LoginPageStep.MFARecovery) {
      return formatMessage(messages.ImsTwoFactorAuthenticationCantAccessTitle);
    }
    return formatMessage(messages.authLoginTitle);
  };

  const redirectToAppWhenUserIsLoggedIn = () => {
    if ((currentUser.id !== undefined && loggedIn) || isOryUserLoggedIn) {
      if (isSuperadmin) {
        navigate(PATHS.SUPERADMIN_DASHBOARD);
      }

      if (isMarketer) {
        navigate(PATHS.MARKETER_ACTIVATION_CODES);
      }

      if (currentUser.id !== undefined) {
        const redirectPath = getRedirectPathForCurrentUser({
          companies: currentUser.companies,
          lastVisitedCompanyId: currentUser.lastVisitedCompanyId,
          lastVisitedSiteId: currentUser.lastVisitedSiteId,
        });
        navigate(redirectPath);
      }
    }
  };

  return {
    getTitle,
    redirectToAppWhenUserIsLoggedIn,
  };
}
