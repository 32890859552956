import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { PasswordInput } from '../../../../../components/HookFormFields/PasswordInput';
import OutlinedTextField from '../../../../../components/HookFormFields/TextField';
import useBreakpoints from '../../../../../helpers/useBreakpoints';
import { IUseOryResponseParser } from '../../../../hooks/oryResponseParser';
import { IOryMFASettings } from '../../../../utils/MFA';
import { OryErrorBar } from '../../../OryErrorBar';
import messages from '../../messages';
import { FormFieldNames, IMFASetupFormData } from '../../validationSchema';

export interface IMFASetupStep2Props {
  oryMFASettings: IOryMFASettings;
  formData: UseFormReturn<IMFASetupFormData>;
  getFormAlertMessage: IUseOryResponseParser['getFormAlertMessage'];
  isSessionRefreshRequired: boolean;
  submitForm: (values: IMFASetupFormData) => Promise<void>;
}

export function MFASetupStep2({
  formData,
  getFormAlertMessage,
  isSessionRefreshRequired,
  oryMFASettings,
  submitForm,
}: IMFASetupStep2Props) {
  const { downMd } = useBreakpoints();
  const { formatMessage } = useIntl();

  const getOryQRCode = () => {
    if (oryMFASettings.setupConfiguration) {
      return (
        <Stack direction={downMd ? 'column' : 'row'}>
          <Box sx={{ textAlign: 'center' }}>
            <img
              src={oryMFASettings.setupConfiguration.qrCodeBase64}
              alt="MFA-QR"
              style={{ height: 'auto', maxWidth: '220px', width: '100%' }}
            />
          </Box>
          <Stack spacing={1} alignItems={'center'} justifyContent={'center'}>
            <Typography variant="body2" color="secondary">
              <FormattedMessage {...messages.TwoFactorAuthenticationModalLinkStep2Text2}></FormattedMessage>
            </Typography>
            <Typography variant="body2" color="secondary">
              <Box sx={{ paddingBottom: 1, wordBreak: 'break-word' }}>
                {oryMFASettings.setupConfiguration.totpSecret}
              </Box>
            </Typography>
          </Stack>
        </Stack>
      );
    }
  };

  return (
    <>
      <OryErrorBar error={getFormAlertMessage()} />
      <Stack direction={'column'} spacing={1}>
        <Typography>
          <FormattedMessage {...messages.TwoFactorAuthenticationModalLinkStep2Text1}></FormattedMessage>
        </Typography>
        {getOryQRCode()}
        <FormProvider {...formData}>
          <form onSubmit={formData.handleSubmit(submitForm)}>
            <OutlinedTextField
              id="totp"
              label={formatMessage(messages.TwoFactorAuthenticationModalLinkStep2AuthCodeTitle)}
              name={FormFieldNames.Totp}
              required
              fullWidth
              helperText={formatMessage(messages.TwoFactorAuthenticationModalLinkStep2AuthCodeHelper)}
            />

            {isSessionRefreshRequired && (
              <Stack>
                <PasswordInput
                  id="current-password"
                  label={formatMessage(messages.SharedLabelCurrentPassword)}
                  name={FormFieldNames.CurrentPassword}
                  required
                  fullWidth
                />
              </Stack>
            )}
          </form>
        </FormProvider>
      </Stack>
    </>
  );
}
