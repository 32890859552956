import { IntlFormatters } from 'react-intl';
import {
  IOryErrorMessageContextDefinition,
  IOryErrorMessageDefinition,
  OryContext,
  OryError,
  OryErrorCustomNamedMessageId,
  OryErrorMessage,
  OryErrorMessageId,
  OryErrorMessageShowMethod,
  OryErrorNamedMessageId,
} from '../types';
import messages from './messages';

function getOryErrorMessages(formatMessage: IntlFormatters['formatMessage']): {
  [oryErrorId: OryErrorMessageId]: IOryErrorMessageDefinition;
} {
  return {
    [OryErrorCustomNamedMessageId.RecoveryCodeMaxAttempts]: {
      message: formatMessage(messages.ImsResetPasswordRecoveryCodeHelperRecoveryCodeMaxAttempts),
      showMethod: OryErrorMessageShowMethod.FieldValidation,
    },
    [OryErrorNamedMessageId.Security_csrf_violation]: {
      message: formatMessage(messages.ImsNewPasswordErrorSessionExpired),
      showMethod: OryErrorMessageShowMethod.AppNotification,
    },
    [OryErrorNamedMessageId.Session_self_service_flow_expired]: {
      contexts: [
        {
          context: OryContext.MFASettings,
          definition: {
            message: formatMessage(messages.TwoFactorAuthenticationModalLinkStep2ErrorSetupSessionExpired),
            showMethod: OryErrorMessageShowMethod.AppNotification,
          },
        },
      ],
      message: formatMessage(messages.ImsNewPasswordErrorSessionExpired),
      showMethod: OryErrorMessageShowMethod.AppNotification,
    },
    [OryErrorNamedMessageId.Session_aal2_required]: {
      message: '',
      showMethod: OryErrorMessageShowMethod.None,
    },

    [OryErrorNamedMessageId.Session_already_available]: {
      contexts: [
        {
          context: OryContext.Login,
          definition: {
            message: '',
            showMethod: OryErrorMessageShowMethod.None,
          },
        },
      ],
      message: formatMessage(messages.ImsResetPasswordErrorLoggedIn),
      showMethod: OryErrorMessageShowMethod.AppNotification,
    },

    [OryErrorNamedMessageId.Session_refresh_required]: {
      contexts: [
        {
          context: OryContext.ChangeCurrentUserPassword,
          definition: {
            message: formatMessage(messages.UserChangePasswordDialogImsErrorSessionExpired),
            showMethod: OryErrorMessageShowMethod.FormAlertNotification,
          },
        },
        {
          context: OryContext.MFASettings,
          definition: {
            message: formatMessage(messages.UserChangePasswordDialogImsErrorSessionExpired),
            showMethod: OryErrorMessageShowMethod.FormAlertNotification,
          },
        },
      ],
      message: formatMessage(messages.ImsNewPasswordErrorSessionExpired),
      showMethod: OryErrorMessageShowMethod.AppNotification,
    },

    [OryErrorNamedMessageId.Browser_location_change_required]: {
      message: '',
      showMethod: OryErrorMessageShowMethod.None,
    },

    [OryErrorNamedMessageId.Recovery_flow_expired_4060005]: {
      message: formatMessage(messages.ImsResetPasswordRecoveryCodeHelperExpiredCode4060005),
      showMethod: OryErrorMessageShowMethod.FieldValidation,
    },

    [OryErrorNamedMessageId.Recovery_flow_invalid_code_4060006]: {
      message: formatMessage(messages.ImsResetPasswordRecoveryCodeHelperInvalidCode4060006),
      showMethod: OryErrorMessageShowMethod.FieldValidation,
    },

    [OryErrorNamedMessageId.Login_flow_invalid_credentials_4000006]: {
      contexts: [
        {
          context: OryContext.ChangeCurrentUserPassword,
          definition: {
            message: formatMessage(messages.UserChangePasswordDialogImsErrorIncorrectPassword),
            showMethod: OryErrorMessageShowMethod.FieldValidation,
          },
        },
        {
          context: OryContext.MFASettings,
          definition: {
            message: formatMessage(messages.UserChangePasswordDialogImsErrorIncorrectPassword),
            showMethod: OryErrorMessageShowMethod.FieldValidation,
          },
        },
      ],
      message: formatMessage(messages.ImsLoginErrorIncorrectCredentials4000006, { link: (chunks) => chunks[0] }),
      showMethod: OryErrorMessageShowMethod.FormAlertNotification,
    },
    [OryErrorNamedMessageId.Login_flow_not_verified_email_4000010]: {
      message: formatMessage(messages.ImsLoginErrorNotActivated4000010),
      showMethod: OryErrorMessageShowMethod.FormAlertNotification,
    },
    [OryErrorNamedMessageId.Recover_flow_leaked_password_4000034]: {
      message: formatMessage(messages.ImsNewPasswordInputHelperTextLeaked4000034),
      showMethod: OryErrorMessageShowMethod.FieldValidation,
    },
    [OryErrorNamedMessageId.Recover_flow_similar_password_4000031]: {
      message: formatMessage(messages.ImsNewPasswordInputHelperTextSimilar4000031),
      showMethod: OryErrorMessageShowMethod.FieldValidation,
    },
    [OryErrorNamedMessageId.Settings_flow_invalid_totp_4000008]: {
      message: formatMessage(messages.TwoFactorAuthenticationModalLinkStep2AuthCodeErrorWrongCode),
      showMethod: OryErrorMessageShowMethod.FieldValidation,
    },
    [OryErrorNamedMessageId.Login_flow_invalid_totp_4000003]: {
      message: formatMessage(messages.ImsTwoFactorAuthenticationAuthCodeErrorWrongCode),
      showMethod: OryErrorMessageShowMethod.FieldValidation,
    },

    [OryErrorNamedMessageId.Session_inactive]: {
      contexts: [
        {
          context: OryContext.ChangeCurrentUserPassword,
          definition: {
            message: '',
            showMethod: OryErrorMessageShowMethod.None,
          },
        },
      ],
      message: formatMessage(messages.ImsNewPasswordErrorSessionExpired),
      showMethod: OryErrorMessageShowMethod.AppNotification,
    },
  };
}

export function getOryGeneralErrorMessage(formatMessage: IntlFormatters['formatMessage']): string {
  return formatMessage(messages.ImsResetPasswordErrorGeneral);
}

export function tryToGetOryErrorMessageDefinition(
  oryErrorId: OryErrorMessageId,
  formatMessage: IntlFormatters['formatMessage']
): IOryErrorMessageDefinition | undefined {
  const message = getOryErrorMessages(formatMessage)[oryErrorId];
  if (message) {
    return message;
  }
  return undefined;
}

export function getOryErrorMessageText(
  oryErrorId: OryErrorMessageId,
  formatMessage: IntlFormatters['formatMessage'],
  context?: OryContext
): string {
  const messageDefinition = tryToGetOryErrorMessageDefinition(oryErrorId, formatMessage);
  if (messageDefinition) {
    if (context && messageDefinition.contexts) {
      const contextDefinition = tryToGetContextDefinition(context, messageDefinition.contexts);
      if (contextDefinition) {
        return contextDefinition.definition.message;
      }
    }
    return messageDefinition.message;
  }

  return '';
}

export function getOryErrorFromOryErrorMessageId(
  oryMessageId: OryErrorMessageId,
  formatMessage: IntlFormatters['formatMessage'],
  context?: OryContext | undefined
): OryError | OryErrorMessage {
  const messageDefinition = tryToGetOryErrorMessageDefinition(oryMessageId, formatMessage);
  if (messageDefinition) {
    return { context: context, id: oryMessageId, showMethod: getOryErrorShowMethod(messageDefinition, context) };
  }
  return false;
}

export function getOryErrorShowMethod(
  messageDefinition: IOryErrorMessageDefinition,
  context?: OryContext
): OryErrorMessageShowMethod {
  if (context && messageDefinition.contexts) {
    const contextDefinition = tryToGetContextDefinition(context, messageDefinition.contexts);
    if (contextDefinition) {
      return contextDefinition.definition.showMethod;
    }
  }
  return messageDefinition.showMethod;
}

export function tryToGetContextDefinition(
  context: OryContext,
  contextDefinitions: IOryErrorMessageContextDefinition[]
): IOryErrorMessageContextDefinition | undefined {
  const messageContext = contextDefinitions.find((m) => m.context === context);
  if (messageContext) {
    return messageContext;
  }
  return undefined;
}
