import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import { addSnackbarSuccess } from '../../../modules/notifications/actions';
import { containsOneOfSpecificErrors } from '../../errors/errorParser';
import { useOrySettingsApi } from '../../hooks/oryApi';
import { ISetMFASettingsParams } from '../../hooks/oryApi/settings/settingsApi';
import { useOryUserPrivilegedSessionRefresh } from '../../hooks/oryPrivilegedSession/useOryUserPrivilegedSessionRefresh';
import { IUseOryResponseParser, useOryResponseParser } from '../../hooks/oryResponseParser';
import { OryContext, OryErrorNamedMessageId } from '../../types';
import messages from './messages';
import { defaultValues, FormFieldNames, getMFASetupFormValidationScheme, IMFASetupFormData } from './validationSchema';

export interface IUseMFASetupModalProps {
  onSuccess: () => void;
  onSessionError: () => void;
}

export interface IUseMFASetupModal {
  setupMFA: (values: ISetMFASettingsParams) => Promise<void>;
  formData: UseFormReturn<IMFASetupFormData, any, undefined>;
  isOryApiFetching: boolean;
  isSessionRefreshRequired: boolean;
  getFormAlertMessage: IUseOryResponseParser['getFormAlertMessage'];
}

export function useMFASetupModal(props: IUseMFASetupModalProps): IUseMFASetupModal {
  const { isOryApiFetching, setMFASettings } = useOrySettingsApi();
  const { clearFormAlertMessage, getFormAlertMessage, parseOryResponse } = useOryResponseParser();
  const [isSessionRefreshRequired, setIsSessionRefreshRequired] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const formData = useForm<IMFASetupFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(getMFASetupFormValidationScheme(formatMessage, isSessionRefreshRequired)),
  });
  const { isOryApiFetching: isOryLoginApiFetching, refreshSession } = useOryUserPrivilegedSessionRefresh({
    context: OryContext.MFASettings,
    validationField: { fieldName: FormFieldNames.CurrentPassword, formData },
  });

  const setupMFA = async (values: ISetMFASettingsParams) => {
    clearFormAlertMessage();
    if (isSessionRefreshRequired && values.currentPassword) {
      const result = await refreshSession(values.currentPassword);
      if (!result) {
        return;
      }
      setIsSessionRefreshRequired(false);
    }

    const response = await setMFASettings({
      csrfToken: values.csrfToken,
      flowId: values.flowId,
      totpValue: values.totpValue,
    });

    parseOryResponse({
      onError: async (error) => {
        if (error) {
          if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_inactive])) {
            navigate(PATHS.LOGOUT);
          }
          if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_refresh_required])) {
            setIsSessionRefreshRequired(true);
          }
          if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_self_service_flow_expired])) {
            props.onSessionError();
          }
        }
      },
      onSuccess: () => {
        dispatch(addSnackbarSuccess(messages.TwoFactorAuthenticationModalLinkSuccess));
        props.onSuccess();
      },
      response,
      validationField: { fieldName: FormFieldNames.Totp, formData },
    });
  };

  return {
    formData,
    getFormAlertMessage,
    isOryApiFetching: isOryApiFetching || isOryLoginApiFetching,
    isSessionRefreshRequired,
    setupMFA,
  };
}
