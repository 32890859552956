import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import actions from '../../../../modules/auth';
import { getThrottlingRetryAfterDate } from '../../../../modules/auth/selectors';
import { OryErrorBar } from '../../../../ory/components/OryErrorBar';
import { useOryUserLogin } from '../../../../ory/hooks/oryUserLogin/useOryUserLogin';
import { getBasicEmailValidation } from '../../../../validations/user/userCommonValidations';
import { LoginAttemptsAlert } from '../../LoginAttemptsAlert';
import messages from '../../messages';
import { AuthLoginForm } from './LoginForm';

interface ILoginForm {
  email: string;
  password: string;
}

interface ILoginFormWrapperParams {
  onOrySecondFactor: () => void;
}

export function LoginFormWrapper(params: ILoginFormWrapperParams) {
  const retryAfterDate = useSelector(getThrottlingRetryAfterDate);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { getFormAlertMessage, isOryApiFetching, loginOryUser } = useOryUserLogin();

  const formData = useForm<ILoginForm>({
    defaultValues: { email: '', password: '' },
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        email: getBasicEmailValidation(
          formatMessage(messages.authLoginEmailInvalid),
          formatMessage(messages.authLoginEmailRequired)
        ),
        password: Yup.string().required(formatMessage(messages.authLoginPasswordRequired)),
      })
    ),
  });

  return (
    <>
      <Box>
        <OryErrorBar error={getFormAlertMessage()} />
        {retryAfterDate && (
          <Box pb={3}>
            <LoginAttemptsAlert throttlingRetryAfter={retryAfterDate} />
          </Box>
        )}
      </Box>

      <FormProvider {...formData}>
        <form
          onSubmit={formData.handleSubmit((values: ILoginForm) =>
            //TODO HIPCS-22198: move this function to separated hook to be callable from account activation page
            dispatch(
              actions.loginRequest({
                email: values.email,
                onUserIsInOry: async () => await loginOryUser(values, params.onOrySecondFactor),
                password: values.password,
              })
            )
          )}
        >
          <AuthLoginForm isSubmitDisabled={!formData.formState.isValid || isOryApiFetching} />
        </form>
      </FormProvider>
    </>
  );
}
