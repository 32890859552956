import { LinkOff } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Typography } from '@mui/material';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionModalError } from '../../../components/_DesignSystemWrappers/Modal';
import { PasswordInput } from '../../../components/HookFormFields/PasswordInput';
import { IModalBase } from '../../../modules/commonTypes';
import { IOryMFASettings } from '../../utils/MFA';
import { OryErrorBar } from '../OryErrorBar';
import messages from './messages';
import { useMFAUnlinkModal } from './useMFAUnlinkModal';
import { FormFieldNames, IMFAUnlinkFormData } from './validationSchema';

export interface IChangeOryUserPasswordModalProps extends IModalBase {
  oryMFASettings: IOryMFASettings;
}

export function MFAUnlinkModal({ onClose, open, oryMFASettings }: IChangeOryUserPasswordModalProps) {
  const { formatMessage } = useIntl();
  const { formData, getFormAlertMessage, isOryApiFetching, isSessionRefreshRequired, unlinkMFA } = useMFAUnlinkModal({
    onSuccess: onClose,
  });

  const submitForm = (values: IMFAUnlinkFormData) => {
    if (oryMFASettings) {
      unlinkMFA({
        csrfToken: oryMFASettings.csrfToken,
        currentPassword: values.currentPassword,
        flowId: oryMFASettings.flowId,
      });
    }
  };

  return (
    <ActionModalError
      icon={<LinkOff />}
      isPrimaryButtonDisabled={isOryApiFetching || !formData.formState.isValid}
      onClose={onClose}
      open={open}
      primaryButtonAction={formData.handleSubmit(submitForm)}
      primaryButtonText={formatMessage(messages.TwoFactorAuthenticationModalUnlinkConfirm)}
      secondaryButtonText={formatMessage(messages.TwoFactorAuthenticationModalUnlinkCancel)}
      title={formatMessage(messages.TwoFactorAuthenticationModalUnlinkTitle)}
    >
      <OryErrorBar error={getFormAlertMessage()} />
      <Typography component="div">
        <FormattedMessage {...messages.TwoFactorAuthenticationModalUnlinkText1}></FormattedMessage>
      </Typography>

      <FormProvider {...formData}>
        <form onSubmit={formData.handleSubmit(submitForm)}>
          {isSessionRefreshRequired && (
            <Stack>
              <PasswordInput
                id="current-password"
                label={formatMessage(messages.SharedLabelCurrentPassword)}
                name={FormFieldNames.CurrentPassword}
                required
                fullWidth
              />
            </Stack>
          )}
        </form>
      </FormProvider>
    </ActionModalError>
  );
}
