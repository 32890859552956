import { InfoBar, InfoBarType, InfoBarVariant } from '@2n/design-system';
import React from 'react';

export interface IWarningBarData {
  title?: string;
  children: React.ReactNode;
}

export function FilledWarningBar(data: IWarningBarData) {
  return (
    <InfoBar
      variant={InfoBarVariant.standard}
      type={InfoBarType.warning}
      title={data.title || undefined}
      sx={{
        backgroundColor: 'warning.alert.background',
        color: 'warning.text',
      }}
    >
      {data.children}
    </InfoBar>
  );
}
