import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import { TYPE_DESCRIPTOR } from '../../../modules/notifications';
import { addSnackbarError } from '../../../modules/notifications/actions';
import { getUserSelector } from '../../../modules/user/selectorsTS';
import { MaxWrongCurrentPasswordAttemptsDuringChangePassword } from '../../config/const';
import { containsOneOfSpecificErrors } from '../../errors/errorParser';
import { OryContext, OryErrorNamedMessageId } from '../../types';
import { useOryLoginApi } from '../oryApi';
import { IErrorHandlingParams, useOryResponseParser } from '../oryResponseParser';
import messages from '../oryUserLogin/messages';

export interface IUseOryUserSessionRefreshProps<FormFields extends FieldValues> {
  validationField: IErrorHandlingParams<FormFields>['validationField'];
  context?: OryContext;
}

export interface IUseOryUserSessionRefresh {
  refreshSession: (password: string) => Promise<boolean>;
  isOryApiFetching: boolean;
}

export function useOryUserPrivilegedSessionRefresh<FormFields extends FieldValues>(
  props: IUseOryUserSessionRefreshProps<FormFields>
): IUseOryUserSessionRefresh {
  const [wrongCurrentPasswordAttempts, setWrongCurrentPasswordAttempts] = useState(0);
  const user = useSelector(getUserSelector);
  const { parseOryResponse } = useOryResponseParser();
  const { isOryApiFetching, loginToOry } = useOryLoginApi();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const refreshSession = async (password: string): Promise<boolean> => {
    const response = await loginToOry({
      context: props.context,
      email: user.data?.email,
      password: password,
      sessionRefresh: true,
    });

    return parseOryResponse({
      onError: (error) => {
        if (error) {
          if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Login_flow_invalid_credentials_4000006])) {
            const attemptsCount = wrongCurrentPasswordAttempts + 1;
            setWrongCurrentPasswordAttempts(attemptsCount);
            if (attemptsCount >= MaxWrongCurrentPasswordAttemptsDuringChangePassword) {
              dispatch(addSnackbarError({ ...messages.ImsLoginErrorIncorrectCredentialsEditProfile }, TYPE_DESCRIPTOR));
              navigate(PATHS.LOGOUT);
            }
          }
        }
      },
      response,
      validationField: props.validationField,
    });
  };

  return {
    isOryApiFetching,
    refreshSession,
  };
}
