import { ArrowForward } from '@mui/icons-material';
import { Link, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import OutlinedTextField from '../../../../components/HookFormFields/TextField';
import messages from './messages';
import { useMFAForm } from './useMFAForm';
import { FormFieldNames } from './validationScheme';

export interface IMFAFormProps {
  onMFARecovery: () => void;
  onSuccess?: () => void;
}

export function MFAForm(props: IMFAFormProps) {
  const { buttonsDirection, buttonsSpacing, formData, formSubmit, isOryApiFetching } = useMFAForm({
    onSuccess: props.onSuccess,
  });

  const { formatMessage } = useIntl();

  return (
    <FormProvider {...formData}>
      <form onSubmit={formData.handleSubmit(formSubmit)}>
        <OutlinedTextField
          label={formatMessage(messages.ImsTwoFactorAuthenticationAuthCodeTitle)}
          name={FormFieldNames.TotpCode}
          required
          fullWidth
          helperText={formatMessage(messages.ImsTwoFactorAuthenticationAuthCodeHelper)}
        />

        <Stack
          direction={buttonsDirection}
          spacing={buttonsSpacing}
          justifyContent="space-between"
          alignItems="center"
          mt={3}
        >
          <Link component="button" onClick={() => props.onMFARecovery()}>
            <FormattedMessage {...messages.ImsTwoFactorAuthenticationCantAccess} />
          </Link>

          <Button
            startIcon={<ArrowForward />}
            disabled={!formData.formState.isValid || isOryApiFetching}
            color="primary"
            id="TOTP-submit"
            size="large"
            type="submit"
            variant="contained"
          >
            <FormattedMessage {...messages.ImsTwoFactorAuthenticationConfirm} />
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
}
