import EditIcon from '@mui/icons-material/Edit';
import LanguageIcon from '@mui/icons-material/Language';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MailOutlined from '@mui/icons-material/MailOutlined';
import MobileFriendlyOutlinedIcon from '@mui/icons-material/MobileFriendlyOutlined';
import Person from '@mui/icons-material/Person';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getOryLoginStatusSelector } from '../../modules/auth/selectors';
import { selectors } from '../../modules/user';
import { MFASettingsButton } from '../../ory/components/MFASettingsButton/MFASettingsButton';
import { OryLoginStatus } from '../../ory/types';
import IconBox from '../IconBox';
import Loader from '../Loader';
import messages from './messages';
import Newsletter from './Newsletter';
import styles from './styles/UserInfoBox';

function UserInfoBox({
  canChangePassword,
  loading,
  onShowEditPasswordModal,
  onShowEditUserModal,
  onShowInstallAppModal,
  onShowSetLanguageModal,
  user,
}) {
  const { pathname } = useLocation();
  const isCompanyOrSiteAdmin = useSelector(selectors.isCompanyOrSiteAdmin);
  const oryLoginStatus = useSelector(getOryLoginStatusSelector);
  const isMFAButtonVisible =
    canChangePassword && isCompanyOrSiteAdmin && oryLoginStatus === OryLoginStatus.SessionConfirmed;

  return (
    <Paper sx={styles.root}>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={styles.infoBox} data-testid="user-box">
          <Person sx={styles.icon} />
          <Box sx={styles.infoBoxUser}>
            <Typography gutterBottom sx={styles.name} variant="h2" data-testid="user-fullname">
              {`${user.firstName} ${user.lastName}`.trim()}
            </Typography>
            <Box sx={styles.email}>
              <IconBox icon={<MailOutlined color="secondary" />}>
                <Typography color="secondary">{user.email}</Typography>
              </IconBox>
            </Box>
            <Newsletter newsletters={user?.newsletters || false} />
            <Box sx={styles.infoButtons}>
              <Box sx={styles.alignLeft}>
                {canChangePassword && (
                  <Button
                    color="primary"
                    onClick={onShowEditPasswordModal}
                    data-testid="change-password"
                    startIcon={<LockOutlinedIcon />}
                  >
                    <FormattedMessage {...messages.UserInfoBoxChangePassword} />
                  </Button>
                )}

                {isMFAButtonVisible && <MFASettingsButton />}

                {!pathname.includes('admins') && (
                  <Button
                    color="primary"
                    onClick={onShowSetLanguageModal}
                    data-testid="change-language"
                    startIcon={<LanguageIcon />}
                  >
                    <FormattedMessage {...messages.UserInfoBoxChangeLanguage} />
                  </Button>
                )}
              </Box>
              <Box sx={styles.alignRight}>
                {!isCompanyOrSiteAdmin && (
                  <Button
                    color="primary"
                    onClick={onShowInstallAppModal}
                    startIcon={<MobileFriendlyOutlinedIcon />}
                    data-testid="install-app"
                  >
                    <FormattedMessage {...messages.UserInfoBoxInstallApp} />
                  </Button>
                )}
                <Button
                  color="primary"
                  onClick={() => onShowEditUserModal(user)}
                  data-testid="edit-profile"
                  startIcon={<EditIcon />}
                >
                  <FormattedMessage {...messages.UserInfoBoxEditProfile} />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Paper>
  );
}

UserInfoBox.propTypes = {
  canChangePassword: PropTypes.bool,
  loading: PropTypes.bool,
  onShowEditPasswordModal: PropTypes.func.isRequired,
  onShowEditUserModal: PropTypes.func.isRequired,
  onShowInstallAppModal: PropTypes.func,
  onShowSetLanguageModal: PropTypes.func,
  user: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lastName: PropTypes.string,
    newsletters: PropTypes.bool,
  }),
};

UserInfoBox.defaultProps = {
  canChangePassword: false,
  loading: false,
  onShowInstallAppModal: null,
  onShowSetLanguageModal: null,
  user: null,
};

export default UserInfoBox;
