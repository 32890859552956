export enum ResetPasswordFormStep {
  EmailForm,
  ORYRecoveryCodeForm,
  MFAForm,
  MFARecovery,
  ORYNewPasswordForm,
}

export interface IResetPasswordFormData {
  email: string;
}
