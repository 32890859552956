import { SettingsFlow } from '@ory/client';
import { AxiosError } from 'axios';
import { IntlFormatters } from 'react-intl';
import { Dispatch } from 'redux';
import { oryApi } from '../../../config/oryFrontendApi';
import { processOryApiError } from '../../../errors/errorParser';
import { getOryErrorFromOryErrorMessageId } from '../../../errors/oryErrorMessages';
import {
  IOryGeneralError,
  OryContext,
  OryErrorMessage,
  OryResponse,
  OryResponseWithData,
  OrySuccess,
} from '../../../types';
import { getCSRFToken } from '../../../utils/CSRFToken';
import { getMFASetup, IOryMFASettings } from '../../../utils/MFA';
import { hasResponseOKStatus } from '../../../utils/responseStatus';
import { tryToGetFlowValidationMessageId } from '../../../utils/validationMessageId';
export interface ISetMFASettingsParams {
  totpValue: string;
  flowId: string;
  csrfToken: string;
  currentPassword?: string;
}

export interface IUnlinkMFASettingsParams {
  flowId: string;
  csrfToken: string;
  currentPassword?: string;
}

export async function updatePassword(
  params: { password: string },
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage']
): Promise<OryResponse> {
  fetchingState(true);
  try {
    const settingsFlowInit = await oryApi.createBrowserSettingsFlow();
    const csrfToken = getCSRFToken(settingsFlowInit.data);

    const settingsFlow = await oryApi.updateSettingsFlow({
      flow: settingsFlowInit.data.id,
      updateSettingsFlowBody: { csrf_token: csrfToken, method: 'password', password: params.password },
    });

    fetchingState(false);

    if (hasResponseOKStatus(settingsFlow)) {
      return handleSettingsFlowValidations(settingsFlow.data, formatMessage, OryContext.ChangeCurrentUserPassword);
    }

    return false;
  } catch (e) {
    fetchingState(false);
    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      context: OryContext.ChangeCurrentUserPassword,
      dispatch,
      formatMessage,
    });
  }
}

export async function getMFASettings(
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage']
): Promise<OryResponseWithData<IOryMFASettings>> {
  fetchingState(true);
  try {
    const settingsFlowInit = await oryApi.createBrowserSettingsFlow();
    const mfaSetup = getMFASetup(settingsFlowInit.data);
    fetchingState(false);
    if (mfaSetup) {
      return mfaSetup;
    }
    return false;
  } catch (e) {
    fetchingState(false);
    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      dispatch,
      formatMessage,
    });
  }
}

export async function setMFASettings(
  params: ISetMFASettingsParams,
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage']
): Promise<OryResponse> {
  fetchingState(true);
  try {
    await oryApi.updateSettingsFlow({
      flow: params.flowId,
      updateSettingsFlowBody: {
        csrf_token: params.csrfToken,
        method: 'totp',
        totp_code: params.totpValue,
      },
    });

    return true;
  } catch (e) {
    fetchingState(false);

    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      context: OryContext.MFASettings,
      dispatch,
      formatMessage,
    });
  }
}

export async function unlinkMFASettings(
  params: IUnlinkMFASettingsParams,
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage']
): Promise<OryResponse> {
  fetchingState(true);
  try {
    await oryApi.updateSettingsFlow({
      flow: params.flowId,
      updateSettingsFlowBody: {
        csrf_token: params.csrfToken,
        method: 'totp',
        totp_unlink: true,
      },
    });

    return true;
  } catch (e) {
    fetchingState(false);

    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      context: OryContext.MFASettings,
      dispatch,
      formatMessage,
    });
  }
}

function handleSettingsFlowValidations(
  response: SettingsFlow,
  formatMessage: IntlFormatters['formatMessage'],
  context: OryContext
): boolean | OryErrorMessage {
  const oryErrorMessageId = tryToGetFlowValidationMessageId(response);
  if (oryErrorMessageId) {
    return getOryErrorFromOryErrorMessageId(oryErrorMessageId, formatMessage, context);
  }
  return OrySuccess;
}
