import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { OryResponse, OryResponseWithData } from '../../../types';
import { IOryMFASettings } from '../../../utils/MFA';
import {
  getMFASettings,
  ISetMFASettingsParams,
  IUnlinkMFASettingsParams,
  setMFASettings,
  unlinkMFASettings,
  updatePassword,
} from './settingsApi';

export interface IChangePasswordProps {
  password: string;
}

export interface IUseSettingsApi {
  updatePassword: (props: IChangePasswordProps) => Promise<OryResponse>;
  getMFASettings: () => Promise<OryResponseWithData<IOryMFASettings>>;
  setMFASettings: (props: ISetMFASettingsParams) => Promise<OryResponse>;
  unlinkMFASettings: (props: IUnlinkMFASettingsParams) => Promise<OryResponse>;

  isOryApiFetching: boolean;
}

export function useOrySettingsApi(): IUseSettingsApi {
  const [isFetching, setIsFetching] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  return {
    getMFASettings: () => getMFASettings(setIsFetching, dispatch, formatMessage),
    isOryApiFetching: isFetching,
    setMFASettings: (props) => setMFASettings(props, setIsFetching, dispatch, formatMessage),
    unlinkMFASettings: (props) => unlinkMFASettings(props, setIsFetching, dispatch, formatMessage),
    updatePassword: (props) => updatePassword(props, setIsFetching, dispatch, formatMessage),
  };
}
