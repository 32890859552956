import { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';
import validationMessage from '../../../../validations/messages';

export interface IMFAFormData {
  totpCode: string;
}

export enum FormFieldNames {
  TotpCode = 'totpCode',
}

export const defaultValues: IMFAFormData = {
  totpCode: '',
};

export function getMFAFormValidationSchema(formatMessage: (message: MessageDescriptor) => string) {
  return yup.object().shape({
    [FormFieldNames.TotpCode]: yup.string().required(formatMessage(validationMessage.validationRequired)),
  });
}
