import { defineMessages } from 'react-intl';

export default defineMessages({
  ImsNewPasswordTitle: {
    defaultMessage: 'Set new password',
    id: 'ims.newPassword.title',
  },
  ImsResetPasswordTitleRecoverAccess: {
    defaultMessage: 'Recover access to your account',
    id: 'ims.resetPassword.title.recoverAccess',
  },
  ImsTwoFactorAuthenticationCantAccessTitle: {
    defaultMessage: 'Can’t access authenticator app?',
    id: 'ims.twoFactorAuthentication.cantAccess.title',
  },
  ImsTwoFactorAuthenticationTitle: {
    defaultMessage: 'Two-factor authentication',
    id: 'ims.twoFactorAuthentication.title',
  },
  resetPasswordTitle: {
    defaultMessage: 'Reset password',
    id: 'resetPassword.title',
  },
});
