import { defineMessages } from 'react-intl';

export default defineMessages({
  ImsTwoFactorAuthenticationCantAccessAlertText: {
    defaultMessage: `The authenticator is the mobile app you set up when enabling two-factor authentication for logging in.
  <p>Some authenticator apps provide backup codes or browser access, which can help if you’ve lost your phone.</p>
  <p>For further assistance, contact our support team at <link>support@2n.com</link>.</p>`,
    id: 'ims.twoFactorAuthentication.cantAccess.alertText',
  },

  ImsTwoFactorAuthenticationCantAccessBack: {
    defaultMessage: 'Back to two-factor authentication',
    id: 'ims.twoFactorAuthentication.cantAccess.back',
  },
});
