import { defineMessages } from 'react-intl';

export default defineMessages({
  ImsTwoFactorAuthenticationAuthCodeHelper: {
    defaultMessage: 'Enter the code from your authenticator app',
    id: 'ims.twoFactorAuthentication.authCode.helper',
  },
  ImsTwoFactorAuthenticationAuthCodeTitle: {
    defaultMessage: 'Authentication code',
    id: 'ims.twoFactorAuthentication.authCode.title',
  },
  ImsTwoFactorAuthenticationCantAccess: {
    defaultMessage: 'Can’t access authenticator?',
    id: 'ims.twoFactorAuthentication.cantAccess',
  },
  ImsTwoFactorAuthenticationConfirm: {
    defaultMessage: 'Log in',
    id: 'ims.twoFactorAuthentication.confirm',
  },

  ImsTwoFactorAuthenticationTitle: {
    defaultMessage: 'Two-factor authentication',
    id: 'ims.twoFactorAuthentication.title',
  },
});
