import { IntlFormatters } from 'react-intl';
import * as yup from 'yup';
import messages from '../../../validations/messages';

export interface IMFASetupFormData {
  totp: string;
  currentPassword?: string;
}

export enum FormFieldNames {
  Totp = 'totp',
  CurrentPassword = 'currentPassword',
}

export const defaultValues: IMFASetupFormData = {
  currentPassword: '',
  totp: '',
};

export function getMFASetupFormValidationScheme(
  formatMessage: IntlFormatters['formatMessage'],
  isCurrentPasswordMandatory?: boolean
) {
  return yup.object().shape({
    [FormFieldNames.Totp]: yup.string().required(formatMessage(messages.validationRequired)),
    [FormFieldNames.CurrentPassword]: isCurrentPasswordMandatory
      ? yup.string().required(formatMessage(messages.validationRequired))
      : yup.string(),
  });
}
