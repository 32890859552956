import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import { addSnackbarSuccess } from '../../../modules/notifications/actions';
import { containsOneOfSpecificErrors } from '../../errors/errorParser';
import { useOrySettingsApi } from '../../hooks/oryApi';
import { IUnlinkMFASettingsParams } from '../../hooks/oryApi/settings/settingsApi';
import { useOryUserPrivilegedSessionRefresh } from '../../hooks/oryPrivilegedSession/useOryUserPrivilegedSessionRefresh';
import { IUseOryResponseParser, useOryResponseParser } from '../../hooks/oryResponseParser';
import { OryContext, OryErrorNamedMessageId } from '../../types';
import { IOryMFASettings } from '../../utils/MFA';
import messages from './messages';
import { defaultValues, FormFieldNames, getMFASetupFormValidationScheme, IMFAUnlinkFormData } from './validationSchema';

export interface IUseOryMFAUnlink {
  unlinkMFA: (values: IUnlinkMFASettingsParams) => Promise<void>;
  oryMFA: IOryMFASettings | undefined;
  formData: UseFormReturn<IMFAUnlinkFormData, any, undefined>;
  isOryApiFetching: boolean;
  isSessionRefreshRequired: boolean;
  getFormAlertMessage: IUseOryResponseParser['getFormAlertMessage'];
}

export interface IUseOryMFASetupProps {
  onSuccess: () => void;
}

export function useMFAUnlinkModal(props: IUseOryMFASetupProps): IUseOryMFAUnlink {
  const [oryMFA] = useState<IOryMFASettings | undefined>();
  const { isOryApiFetching, unlinkMFASettings } = useOrySettingsApi();
  const { clearFormAlertMessage, getFormAlertMessage, parseOryResponse } = useOryResponseParser();
  const [isSessionRefreshRequired, setIsSessionRefreshRequired] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const formData = useForm<IMFAUnlinkFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(getMFASetupFormValidationScheme(formatMessage, isSessionRefreshRequired)),
  });
  const { isOryApiFetching: isOryLoginApiFetching, refreshSession } = useOryUserPrivilegedSessionRefresh({
    context: OryContext.MFASettings,
    validationField: { fieldName: FormFieldNames.CurrentPassword, formData },
  });

  const unlinkMFA = async (values: IUnlinkMFASettingsParams) => {
    clearFormAlertMessage();
    if (isSessionRefreshRequired && values.currentPassword) {
      const result = await refreshSession(values.currentPassword);
      if (!result) {
        return;
      }
      setIsSessionRefreshRequired(false);
    }

    const response = await unlinkMFASettings({
      csrfToken: values.csrfToken,
      currentPassword: '',
      flowId: values.flowId,
    });

    parseOryResponse({
      onError: (error) => {
        if (error) {
          if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_inactive])) {
            navigate(PATHS.LOGOUT);
          }
          if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_refresh_required])) {
            setIsSessionRefreshRequired(true);
          }
        }
      },
      onSuccess: () => {
        dispatch(addSnackbarSuccess(messages.TwoFactorAuthenticationModalUnlinkSuccess));
        props.onSuccess();
      },
      response,
    });
  };

  return {
    formData,
    getFormAlertMessage,
    isOryApiFetching: isOryApiFetching || isOryLoginApiFetching,
    isSessionRefreshRequired,
    oryMFA,
    unlinkMFA,
  };
}
