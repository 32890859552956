import { Theme } from '@mui/material/styles';

export const styles = {
  alert: {
    position: 'absolute',
    top: (theme: Theme) => ({
      md: theme.appNotificationHeightLargeDevice,
      xs: theme.appNotificationHeightSmallDevice,
    }),
    width: '100%',
  },
  sub: {
    '& a': {
      color: 'common.white',
      textDecoration: 'underline',
      textTransform: 'lowercase',
    },
    color: 'common.white',
    mb: 9,
    mt: 10,
    textAlign: 'center',
  },
};
