import { SettingsFlow } from '@ory/client';
import { containsOneOfSpecificErrors } from '../errors/errorParser';
import { OryErrorMessage, OryErrorNamedMessageId } from '../types';
import { getCSRFToken } from './CSRFToken';

export interface IOryMFASettings {
  setupConfiguration?: {
    qrCodeBase64: string;
    totpSecret: string;
  };
  isEnabled: boolean;
  flowId: string;
  csrfToken: string;
}

export function getMFASetup(response: SettingsFlow): IOryMFASettings {
  const totpNodes = response.ui.nodes.filter((node) => node.group === 'totp');
  let qrCodeBase64;
  let totpSecret;
  let setupConfiguration;
  let isEnabled = false;

  const csrfToken = getCSRFToken(response);
  const flowId = response.id;

  if (totpNodes) {
    totpNodes.forEach((node) => {
      if (node.attributes.node_type === 'img' && node.attributes.id === 'totp_qr') {
        qrCodeBase64 = node.attributes.src;
      }

      if (node.attributes.node_type === 'text' && node.attributes.id === 'totp_secret_key') {
        totpSecret = node.attributes.text.text;
      }

      if (node.attributes.node_type === 'text' && node.attributes.id === 'totp_secret_key') {
        totpSecret = node.attributes.text.text;
      }

      if (node.attributes.node_type === 'input' && node.attributes.name === 'totp_unlink') {
        isEnabled = true;
      }
    });

    if (qrCodeBase64 && totpSecret) {
      setupConfiguration = {
        qrCodeBase64,
        totpSecret,
      };
    }
  }
  return { csrfToken, flowId, isEnabled, setupConfiguration };
}

export function isSecondFactorNeeded(error: OryErrorMessage | undefined, onSecondFactor: () => void) {
  if (error && containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Browser_location_change_required])) {
    onSecondFactor();
  }
}
