import { defineMessages } from 'react-intl';

export default defineMessages({
  SharedLabelCurrentPassword: {
    defaultMessage: 'Current password',
    id: 'shared.label.currentPassword',
  },
  TwoFactorAuthenticationModalUnlinkCancel: {
    defaultMessage: 'Cancel',
    id: 'twoFactorAuthentication.modal.unlink.cancel',
  },
  TwoFactorAuthenticationModalUnlinkConfirm: {
    defaultMessage: 'UNLINK',
    id: 'twoFactorAuthentication.modal.unlink.confirm',
  },
  TwoFactorAuthenticationModalUnlinkSuccess: {
    defaultMessage: 'Authenticator app unlinked successfully',
    id: 'twoFactorAuthentication.modal.unlink.success',
  },
  TwoFactorAuthenticationModalUnlinkText1: {
    defaultMessage: `Unlinking the authenticator will disable two-factor authentication.
<p>You can set it up again at any time with the same or a different app.</p>`,
    id: 'twoFactorAuthentication.modal.unlink.text1',
  },
  TwoFactorAuthenticationModalUnlinkTitle: {
    defaultMessage: 'Two-factor authentication',
    id: 'twoFactorAuthentication.modal.unlink.title',
  },
});
