import { IntlFormatters } from 'react-intl';
import * as yup from 'yup';
import messages from '../../../validations/messages';

export interface IMFAUnlinkFormData {
  currentPassword?: string;
}

export enum FormFieldNames {
  CurrentPassword = 'currentPassword',
}

export const defaultValues: IMFAUnlinkFormData = {
  currentPassword: undefined,
};

export function getMFASetupFormValidationScheme(
  formatMessage: IntlFormatters['formatMessage'],
  isCurrentPasswordMandatory?: boolean
) {
  return yup.object().shape({
    [FormFieldNames.CurrentPassword]: isCurrentPasswordMandatory
      ? yup.string().required(formatMessage(messages.validationRequired))
      : yup.string(),
  });
}
