import { FieldValue, FieldValues, UseFormReturn } from 'react-hook-form';
import {
  OryCancelableResponse,
  OryContext,
  OryErrorMessage,
  OryErrorMessageId,
  OryResponseWithData,
} from '../../types';

export type OryResponseParserResult = true | false;

export interface IFormAlertMessage {
  message: string;
  id: OryErrorMessageId;
  context?: OryContext;
}

export interface IValidationField<FormFields extends FieldValues> {
  formData: UseFormReturn<FormFields>;
  fieldName: FieldValue<FormFields>;
}

export interface IUseOryResponseParser {
  parseOryResponseWithData: <TData extends object, FormFields extends FieldValues>(
    params: IParseOryResponseWithDataParams<TData, FormFields>
  ) => OryResponseParserResult;
  parseOryResponse: <FormFields extends FieldValues>(
    params: IParseOryResponseParams<FormFields>
  ) => OryResponseParserResult;
  getFormAlertMessage: () => IFormAlertMessage | undefined;
  clearFormAlertMessage: () => void;
}

export interface IErrorHandlingParams<FormFields extends FieldValues> {
  validationField?: IValidationField<FormFields>;
  onError?: ErrorCallback;
}

export interface IParseOryResponseParams<FormFields extends FieldValues> extends IErrorHandlingParams<FormFields> {
  response: OryCancelableResponse;
  onSuccess?: () => void;
}

export interface IParseOryResponseWithDataParams<TData extends object, FormFields extends FieldValues>
  extends IErrorHandlingParams<FormFields> {
  response: OryResponseWithData<TData>;
  onSuccess: (data: TData) => void;
}

type ErrorCallback = (error?: OryErrorMessage) => void;
