import { Dispatch } from 'redux';
import { setOryLoginStatus } from '../../../modules/auth/actions';
import { getCurrentUser } from '../../../modules/user/actions';
import { OryLoginStatus, OrySuccess } from '../../types';
import { IUseLoginApi } from '../oryApi';
import { IUseOryResponseParser } from '../oryResponseParser';

export interface ICheckOrySessionAndGetCurrentUserProps {
  parseOryResponse: IUseOryResponseParser['parseOryResponse'];
  getOryCurrentUserSession: IUseLoginApi['getOryCurrentUserSession'];
  dispatch: Dispatch;
}

export async function checkOrySessionAndGetCurrentUser({
  dispatch,
  getOryCurrentUserSession,
  parseOryResponse,
}: ICheckOrySessionAndGetCurrentUserProps): Promise<void> {
  const currentUserSessionResponse = await getOryCurrentUserSession();
  if (parseOryResponse({ response: currentUserSessionResponse }) === OrySuccess) {
    dispatch(setOryLoginStatus(OryLoginStatus.SessionConfirmed));
    dispatch(getCurrentUser());
  }
}
