import { OryErrorMessage } from '../../types';
import { IUseLoginApi } from '../oryApi';
import { IUseOryResponseParser } from '../oryResponseParser';

export interface IRefreshLoginForCurrentUserProps {
  parseOryResponse: IUseOryResponseParser['parseOryResponse'];
  loginToOry: IUseLoginApi['loginToOry'];
  loginProps: { email: string; password: string };
  onSuccess: () => void;
  onError: (error?: OryErrorMessage) => void;
}

export async function refreshLoginForCurrentUser({
  loginProps,
  loginToOry,
  onError,
  onSuccess,
  parseOryResponse,
}: IRefreshLoginForCurrentUserProps): Promise<void> {
  const response = await loginToOry({
    email: loginProps.email,
    password: loginProps.password,
    sessionRefresh: true,
  });

  parseOryResponse({
    onError,
    onSuccess,
    response,
  });
}
