import { Key } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '../../../hooks/useModal';
import { MFASetupModal as MFASetupModalComponent } from './../MFASetupModal';
import { MFAUnlinkModal as MFAUnlinkModalComponent } from './../MFAUnlinkModal';
import messages from './messages';
import { useMFASettingsButton } from './useMFASettingsButton';

export function MFASettingsButton() {
  const { Modal: MFASetupModal, onOpen: openMFASetupModal } = useModal({
    Modal: MFASetupModalComponent,
  });
  const { Modal: MFAUnlinkModal, onOpen: openMFAUnlinkModal } = useModal({
    Modal: MFAUnlinkModalComponent,
  });
  const { fetchMFASettings, isOryApiFetching, oryMFA } = useMFASettingsButton({
    openMFASetupModal,
    openMFAUnlinkModal,
  });

  return (
    <>
      <LoadingButton
        size="medium"
        variant="text"
        loading={isOryApiFetching}
        loadingPosition="start"
        startIcon={<Key />}
        onClick={fetchMFASettings}
      >
        <FormattedMessage {...messages.UserInfoBoxTwoFactorAuthentication} />
      </LoadingButton>
      {<MFAUnlinkModal oryMFASettings={oryMFA!} />}
      <MFASetupModal oryMFASettings={oryMFA!} />
    </>
  );
}
