import { Check } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionModal } from '../../../components/_DesignSystemWrappers/Modal';
import { PasswordInput } from '../../../components/HookFormFields/PasswordInput';
import { IModalBase } from '../../../modules/commonTypes';
import { OryErrorBar } from '../OryErrorBar';
import messages from './messages';
import { useChangePasswordModal } from './useChangeOryPasswordModal';
import { FormFieldNames } from './validationSchema';

export interface IChangeOryUserPasswordModalProps extends IModalBase {
  currentUserEmail: string;
}

export function ChangeOryUserPasswordModal({ currentUserEmail, onClose, open }: IChangeOryUserPasswordModalProps) {
  const { formatMessage } = useIntl();
  const {
    formData,
    getFormAlertMessage,
    isOryApiFetching,
    isPasswordHelperTextVisible,
    isSessionRefreshRequired,
    updatePassword,
  } = useChangePasswordModal({
    email: currentUserEmail,
    onSuccess: onClose,
  });

  return (
    <ActionModal
      icon={<Check />}
      isPrimaryButtonDisabled={!formData.formState.isValid || isOryApiFetching}
      onClose={onClose}
      open={open}
      primaryButtonAction={formData.handleSubmit(updatePassword)}
      primaryButtonText={formatMessage(messages.UserChangePasswordDialogImsFormActionsSubmit)}
      secondaryButtonText={formatMessage(messages.UserChangePasswordDialogImsFormActionsCancel)}
      title={formatMessage(messages.UserChangePasswordDialogImsTitle)}
    >
      <FormProvider {...formData}>
        <form onSubmit={formData.handleSubmit(updatePassword)}>
          <Stack direction="column" py={2}>
            <OryErrorBar error={getFormAlertMessage()} />
            <PasswordInput
              id="password"
              label={formatMessage(messages.UserChangePasswordDialogImsLabelNewPassword)}
              helperText={
                isPasswordHelperTextVisible && <FormattedMessage {...messages.ImsNewPasswordInputHelperText} />
              }
              name={FormFieldNames.Password}
              required
              fullWidth
            />
            {isSessionRefreshRequired && (
              <Stack>
                <PasswordInput
                  id="current-password"
                  label={'Current password'}
                  name={FormFieldNames.CurrentPassword}
                  fullWidth
                />
              </Stack>
            )}
          </Stack>
        </form>
      </FormProvider>
    </ActionModal>
  );
}
