import { Dialog, IconPosition } from '@2n/design-system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, ButtonProps, MobileStepper } from '@mui/material';
import React, { useState } from 'react';
import useBreakpoints from '../../../../../helpers/useBreakpoints';

export interface IMultistepDialogProps {
  title: string;
  backButtonLabel: string;
  cancelButtonLabel: string;
  nextButtonLabel: string;
  orderedSteps: IStep[];
  submitButton: ISubmitButton;
  onClose: () => void;
  open: boolean;
  onSubmit: () => void;
  backSkip?: number[];
  minContentHeight?: number;
}

export interface IStep {
  children: React.ReactNode;
  isBackDisabled?: boolean;
  isNextDisabled?: boolean;
}

export interface ISubmitButton {
  label: string;
  icon?: React.ReactNode;
  isDisabled?: boolean;
}

export interface IMultistepDialogRefAttributes {
  handleClickNext: () => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

export function MultistepDialog({
  backButtonLabel,
  backSkip,
  cancelButtonLabel,
  minContentHeight,
  nextButtonLabel,
  onClose,
  onSubmit,
  open,
  orderedSteps,
  submitButton,
  title,
}: IMultistepDialogProps) {
  const [step, setStep] = useState(0);
  const totalSteps = orderedSteps.length;
  const isLastStep = step === totalSteps - 1;
  const currentStep = orderedSteps[step];
  const { downSm } = useBreakpoints();

  function handleClickNext() {
    return setStep((prevStep: number) => Math.min(prevStep + 1, totalSteps - 1));
  }

  const handleClickBack = () =>
    setStep((prevStep: number) => {
      const arr = [prevStep - 1, 0];
      const arrFiltered = arr.filter((item) => !backSkip?.includes(item));
      return Math.max(...arrFiltered);
    });

  const primaryButton = {
    action: isLastStep ? onSubmit : handleClickNext,
    icon: {
      icon: isLastStep ? submitButton?.icon : <ArrowForwardIcon />,
      iconPosition: (isLastStep ? 'start' : 'end') as IconPosition,
    },
    isDisabled: currentStep?.isNextDisabled || (isLastStep && submitButton?.isDisabled),
    text: isLastStep ? submitButton.label : nextButtonLabel,
  };

  const secondaryButton = {
    action: onClose,
    color: 'secondary' as ButtonProps['color'],
    text: cancelButtonLabel,
    variant: 'text' as ButtonProps['variant'],
  };

  const ternaryButton = {
    action: handleClickBack,
    color: 'primary' as ButtonProps['color'],
    icon: {
      icon: <ArrowBackIcon />,
      iconPosition: 'start' as IconPosition,
    },
    isDisabled: currentStep?.isBackDisabled,
    text: downSm ? '' : backButtonLabel,
  };

  return (
    <Dialog
      footerOptions={{
        primaryButton: primaryButton,
        secondaryButton: secondaryButton,
        ternaryButton: step === 0 ? undefined : ternaryButton,
      }}
      onClose={onClose}
      open={open}
      title={title}
      hasBodyContentPadding={false}
    >
      <Box sx={{ minHeight: minContentHeight, pb: 2, pt: 1, px: 3 }}>{currentStep?.children}</Box>
      <MobileStepper
        activeStep={step + 1}
        position="static"
        steps={totalSteps + 1}
        sx={{
          '& .MuiLinearProgress-root': {
            width: '100%',
          },
          p: 0,
        }}
        variant="progress"
        backButton={undefined}
        nextButton={undefined}
      />
    </Dialog>
  );
}
